
import { DataClient } from "@/services/DataClient";
import { useLocationStore } from "@/stores/LocationStore";
import { Component, Vue, Watch } from "vue-property-decorator";
import { RouterView } from "vue-router";

import Header1Section from "@/sections/Header1Section.vue";
import Header2Section from "@/sections/Header2Section.vue";
import { BaseView } from "@/services/BaseView";
import { loadLanguageAsync } from "@/i18n";

@Component({
    components: {
        Header1Section,
        Header2Section,
    },
})
export default class extends BaseView {
    sidebar = false;
    language = this.$i18n.locale;

    showLanguageSelector = true;

    targetElevation = 0;
    targetBackgroundColor = "transparent";

    languages = [
        {
            title: "Español",
            code: "es",
        },
        {
            title: "English",
            code: "en",
        },
        {
            title: "Deutsch",
            code: "de",
        },
        {
            title: "Français",
            code: "fr",
        },
        {
            title: "Italiano",
            code: "it",
        },
    ];

    // @Watch("$vuetify.breakpoint.smAndDown", { immediate: true })
    // onBreakpointChanged(value: boolean) {
    //     console.log("onBreakpointChanged", value);
    //     this.sidebar = false;
    // }

    created() {
        useLocationStore().initialize();
    }

    get jeep() {
        // return this.buildNavigationRoute("category", { id: "000b30d5-aca9-4433-9ee3-1c77d765516a" });
        const activity = DataClient.instance.getCategoryById("000b30d5-aca9-4433-9ee3-1c77d765516a");
        return this.buildNavigationRoute("category", { id: activity.slug });
    }

    get activities() {
        // return this.buildNavigationRoute("activities");
        const activity = DataClient.instance.getCategoryById("52639ac9-14af-4dd9-ba26-7832c1832814");
        return this.buildNavigationRoute("category", { id: activity.slug });
    }

    async setLanguage(code: string) {
        // await DataClient.instance.loadConfiguration(code);
        // this.$router.push({ name: "home" + "." + code });

        const oldName = (this.$route.name || "").slice(0, -3);
        let id: string | undefined;

        if (oldName === "category") {
            id = DataClient.instance.getCategoryBySlug(this.$route.params.id as string)?.id;
        } else if (oldName === "activity") {
            id = DataClient.instance.getActivityBySlug(this.$route.params.id as string)?.id;
        }

        const newName = oldName + "." + code;

        // make sure configuration is loaded
        DataClient.instance.loadConfiguration(code).then(async () => {
            await loadLanguageAsync(code);
            this.$i18n.locale = code;

            if (oldName === "category" && id) {
                this.$router.push({ name: newName, params: { id: DataClient.instance.getCategoryById(id)?.slug }, query: this.$route.query });
            } else if (oldName === "activity" && id) {
                this.$router.push({ name: newName, params: { id: DataClient.instance.getActivityById(id)?.slug }, query: this.$route.query });
            } else {
                this.$router.push({ name: newName, params: this.$route.params, query: this.$route.query });
            }
        });
    }

    get headerType() {
        return DataClient.instance.configuration.headerType;
    }

    agentsLogin() {
        window.open("https://agents.intrabooking.com", "_blank");
    }
}
