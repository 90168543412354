import "./ClassComponentHooks";

import Vue from "vue";
import VueMeta from "vue-meta";
import { createPinia, PiniaVuePlugin } from "pinia";

import { DataClient } from "@/services/DataClient";

import { i18n, loadLanguageAsync } from "./i18n";
import { initializeRouter } from "./router";
import vuetify from "./plugins/vuetify";

import App from "./App.vue";
import VideoBackground from 'vue-responsive-video-background-player';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import "./directives/FadeIn";

Vue.component('video-background', VideoBackground);
Vue.component('VuePhoneNumberInput', VuePhoneNumberInput);

Vue.component("GlobalFooter", () => import("./components/GlobalFooter.vue"));
Vue.component("CategoryPill", () => import("./components/CategoryPill.vue"));
Vue.component("ExclusivePill", () => import("./components/ExclusivePill.vue"));
Vue.component("ImageGallery", () => import("./components/ImageGallery.vue"));
Vue.component("ImageSlideshow", () => import("./components/ImageSlideshow.vue"));
Vue.component("LoaderAnimation", () => import("./components/LoaderAnimation.vue"));
Vue.component("ZoomCard", () => import("./components/ZoomCard.vue"));
Vue.component("ZoneBanner", () => import("./components/ZoneBanner.vue"));
Vue.component("ScrollDownIcon", () => import("./components/ScrollDownIcon.vue"));

// Booking components
Vue.component("PlaceAutocomplete", () => import("./components/PlaceAutocomplete.vue"));
Vue.component("OccupancySelector", () => import("./components/OccupancySelector.vue"));
Vue.component("PaxSelector", () => import("./components/PaxSelector.vue"));
Vue.component("BookingMask", () => import("./components/BookingMask.vue"));
Vue.component("MobileBookingMask", () => import("./components/MobileBookingMask.vue"));

// Image components
Vue.component("AnimatedImageList", () => import("./components/AnimatedImageList.vue"));

// Activity components
Vue.component("MeetingPointInformationMenu", () => import("./components/MeetingPointInformationMenu.vue"));
// Vue.component("ActivityThumbnail", () => import("./components/ActivityThumbnail.vue"));
Vue.component("HorizontalActivityThumbnail", () => import("./components/HorizontalActivityThumbnail.vue"));
Vue.component("VerticalActivityThumbnail", () => import("./components/VerticalActivityThumbnail.vue"));

// Home page components
Vue.component("HomeActivitySection", () => import("./components/HomeActivitySection.vue"));
Vue.component("HomeMosaicSection", () => import("./components/HomeMosaicSection.vue"));
Vue.component("HomeCarousel", () => import("./components/HomeCarousel.vue"));
Vue.component("HomeBookingMask", () => import("./components/HomeBookingMask.vue"));
Vue.component("HomeClaims", () => import("./components/HomeClaims.vue"));


Vue.config.productionTip = false;

Vue.use(VueMeta);
Vue.use(PiniaVuePlugin);

const pinia = createPinia();

async function bootstrap() {
    await DataClient.instance.loadGlobalConfiguration();

    const { router, initialLocale } = initializeRouter();

    (window as any).__router = router;

    // make sure to set the language and load configuration before creating the app
    Promise.all([
        DataClient.instance.loadConfiguration(initialLocale),
        loadLanguageAsync(initialLocale)
    ]).then(() => {
        // TODO
        //document.title = DataClient.instance.configuration.website.name;
        document.title = "FunIslandMallorca | Experiences & Activities in Mallorca";

        // read parameter "affiliate" from query string and store it in localstorage for later use
        // keep associated affiliate for 24 hours in localstorage
        const affiliate = new URLSearchParams(window.location.search).get("affiliate");
        if (affiliate) {
            localStorage.setItem("affiliate", affiliate);
            localStorage.setItem("affiliateDate", new Date().toISOString());
        }

        new Vue({
            router,
            vuetify,
            pinia,
            i18n,
            render: (h) => h(App),
        }).$mount("#app");
    });
}

bootstrap();
